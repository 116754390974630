"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { usePlausibleEvent } from "@/hooks/usePlausibleEvent";
import { cn } from "@/lib/utils";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";

type SearchBarProps = React.ComponentProps<typeof Input>;

export const SearchBar = ({ className, ...props }: SearchBarProps) => {
  const router = useRouter();
  const queryParams = useSearchParams();
  const pathname = usePathname();
  const plausible = usePlausibleEvent();

  const [searchValue, setSearchValue] = useState(
    queryParams.get("q")?.toString()
  );

  const handleSearch = (query?: string) => {
    const params = new URLSearchParams(queryParams);

    if (query) {
      plausible("search", { props: { "search-query": query } });

      params.set("q", query);
    } else {
      params.delete("q");
    }

    router.replace(`${pathname}?${params.toString()}`);
  };

  return (
    <form
      className={cn("w-full flex flex-row items-center", className)}
      onSubmit={(e) => {
        e.preventDefault();

        handleSearch(searchValue);
      }}
    >
      <Input
        type="search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        {...props}
      />

      <Button className="w-10 rounded-l-none" type="submit" variant={"primary"}>
        <FaSearch className="w-4 h-4 grow-0 shrink-0" />
      </Button>
    </form>
  );
};
