"use client";

import { Badge } from "@/components/ui/badge";
import { TabsTrigger } from "@/components/ui/tabs";
import { ActiveTab } from "@/lib/models";
import { usePathname, useRouter } from "next/navigation";

// TODO - Remove this when premium is enabled
const disabledPremiumTab = process.env.NEXT_PUBLIC_DISABLE_PREMIUM === "true";

export const PremiumRepoTabTrigger = () => {
  const router = useRouter();
  const pathname = usePathname();

  const handleClick = () => {
    router.replace(`${pathname}?tab=premium`, { scroll: false });
  };

  return (
    <div className="relative">
      <div className="absolute -top-4 -left-10 -rotate-12 text-sm">
        <Badge variant={"shiny"} className="select-none">
          <span className="font-bold">Coming Soon</span>
        </Badge>
      </div>
      <TabsTrigger
        disabled={disabledPremiumTab}
        onClick={handleClick}
        value={"premium" as ActiveTab}
      >
        Premium 💎
      </TabsTrigger>
    </div>
  );
};

export const PublicRepoTabTrigger = () => {
  const router = useRouter();
  const pathname = usePathname();

  const handleClick = () => {
    router.replace(`${pathname}?tab=public`, { scroll: false });
  };

  return (
    <TabsTrigger onClick={handleClick} value={"public" as ActiveTab}>
      Open Source 🌱
    </TabsTrigger>
  );
};
