import { usePlausible } from "next-plausible";

type RepoEventProps = { repo: string };

type RepoCheckoutEventProps = {
  repo: string;
  github_user?: string;
  user_email?: string;
};

type SearchEventProps = { "search-query": string };

type PlausibleEvent = {
  search: SearchEventProps;
  "unlock-repo-cta": RepoEventProps;
  "view-on-github-cta": RepoEventProps;
  "repo-checkout": RepoCheckoutEventProps;
  "header-login": never;
  "sign-in-with-github": never;
};

export const usePlausibleEvent = () => {
  return usePlausible<PlausibleEvent>();
};
